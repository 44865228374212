/* ==============
  LAYOUT
===================*/
html,
body,
.full-screen {
    height: 100%;
}
body {
    margin: 0;
}
.full-screen {
    display: flex;
    overflow: hidden;
    flex-direction: column;
}
.full-screen > * {
    flex: 0 0 auto;
    overflow: auto;
}
.full-screen > .header {
    display: flex;
    z-index: 1030;
}
.full-screen > .header > * {
    flex: 0 0 auto;
    overflow: auto;
}
.full-screen > .header > .header-left {
    width: 200px;
}
.full-screen > .header > .header-right {
    flex: 1 1 auto;
}
.full-screen > .main {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
}
.full-screen .main .sidebar, .full-screen .main .entity-list  {
    &::-webkit-scrollbar-track{
        box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
	    background-color: $clr-body-bkg;
    }
    &::-webkit-scrollbar
    {
        width: 5px;
        background-color: $clr-body-bkg;
    }
    &::-webkit-scrollbar-thumb
    {
        background-color: $clr-nav-side-accent;
    }
}


$views: ( con-entities-layout,
          con-dashboard-layout,
          con-static,
          con-powers,
          con-settings,
          con-start,
          con-layout,
          con-lists,
          con-task-manager-layout,
          con-dep,
          con-powers-layout,
          con-missing-events,
          con-kid-layout,
          con-doc-process-layout,
          con-marketcap,
          con-shareholders,
          con-company-reports,
          con-mozenda,
          con-estimates-layout,
          con-press-release
        );

@each $view in $views {
    .full-screen > .main > #{ $view } {
        flex: 1 1 auto;
        display: flex;
        overflow: hidden;
    }
    .full-screen > .main > #{ $view } > * {
        flex: 0 0 auto;
        overflow: auto;
    }
    @if $view == con-entities-layout or $view == con-task-manager-layout or  $view == con-kid-layout or $view == con-doc-process-layout{
        .full-screen > .main > #{ $view } > .sidebar {
            width: 57px;
        }
    }
    @else if $view == con-company-reports {
      .full-screen > .main > #{ $view } > .sidebar {
        width: 205px;
      }
    }
    @else if $view == con-estimates-layout {
        .full-screen > .main > #{ $view } > .sidebar {
          width: 205px;
        }
      }
    @else {
        .full-screen > .main > #{ $view } > .sidebar {
            width: 180px;
        }
    }
    .full-screen > .main > #{ $view } > .content {
        flex: 1 1 auto;
        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 8px rgba(0,0,0,0.3);
            background-color: $clr-body-bkg;
        }
        &::-webkit-scrollbar
        {
            width: 8px;
            background-color: $clr-body-bkg;
        }
        &::-webkit-scrollbar-thumb
        {
            background-color: $clr-nav-side-accent;
        }
    }
}
